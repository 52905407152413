$primary-color: #56BFA3;
$secondery-color: #14345a;
$prim-font: 'Helvetica Neue', Roboto;

$lightfont: 'helveticaneuelight';
$regularfont: 'helveticaneueregular';
$boldfont: 'helveticaneuebold';

.custom-container {
    padding-left: 150px;
    padding-right: 160px;

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}