@font-face {
    font-family: 'helveticaneuebold';
    src: url('../fonts/helvetica_neu_bold-webfont.woff2') format('woff2'),
         url('../fonts/helvetica_neu_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaneuelight';
    src: url('../fonts/helveticaneue_light-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaneueregular';
    src: url('../fonts/helveticaneue-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.header {
    min-height: 100vh;
    box-sizing: border-box;

    .top-color {
        height: 24px;
        background: $primary-color;
        width: 100%;
    }

    &-logo {
        img {
            width: 250px;
        }
    }

    &-top {
        padding-top: 60px;

        .header-logo {

        }
    
        .header-btn {
            margin-top: 10px;
            text-align: right;
        }
    }

    .slider {
        margin-top: 150px;

        &-content {
            text-align: center;

            img {
                width: 20%;
            }
        }
    }

    .loading-down {
        position: absolute;
        bottom: 40px;
        width: 100%;
        text-align: center;

        img {
            width: 15px;
        }
    }
}

.about {
    background: #f2f2f2;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    &-content {
        height: 100%;
        .detail {
            margin-top: 100px;
            padding-right: 30px;
            padding: 40px;
            border-radius: 20px;

            .heading {
                font: 56px/65px $boldfont;
                color: $primary-color;
                font-weight: 400;
                margin-bottom: 20px;
            }
    
            .txt {
                font: 22px/40px $lightfont;
                color: rgba(0,0,0, .8);
                letter-spacing: 2px;
            }
        }

        &-img {
            max-width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.ceremony {
    font: 26px/31px $boldfont;
    color: #56bfa3;
}

.board {
    min-height: 100vh;
    background: url('../img/board-bg.png');

    &-heading {
        text-align: center;
        color: #fff;
        padding-top: 85px;
        font: 30px/36px $boldfont;
        letter-spacing: 3px;
        position: relative;

        &::after {
            content: '';
            width: 132px;
            height: 1px;
            background: $primary-color;
            position: absolute;
            bottom: -80px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .board-member {
        text-align: center;
        margin-top: 145px;
        position: relative;

        &-img-bg {
            width: 306px;
            height: 306px;
            background: $secondery-color;
            border-radius: 360px;
            display: inline-block;
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                top: 17px;
                bottom: 17px;
                right: 17px;
                left: 17px;
                background: rgba(#10cfc9, .6);
                position: absolute;
                border-radius: 200px;
                
            }

            .board-member-img {
                width: 240px;
                height: 240px;
                border-radius: 360px;
                overflow: hidden;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 50%;
                margin-left: -120px;
                position: relative;
                z-index: 9999;

                img {
                    width: 100%;
                    -webkit-transition: all 1s ease-in-out;
                    -moz-transition: all 1s ease-in-out;
                    -ms-transition: all 1s ease-in-out;
                    -o-transition: all 1s ease-in-out;
                    transition: all 1s ease-in-out;

                    &.fadeout {
                        opacity: 0;
                    }
                }
            }
        }

        &-detail {
            margin-top: 60px;

            .member-name {
                font: 30px/36px $boldfont;
                letter-spacing: 2px;
                color: #fff;
                font-family: 'Helvetica Neue', Roboto;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .position {
                margin-top: 7px;
                margin-bottom: 7px; 
                font: 30px/36px $lightfont;
                color: #fff;
                font-style: italic;
                display: inline-block;
            }

            .member-dpt {
                font: 24px/30px $lightfont;
                letter-spacing: 1px;
                color: #fff;
                font-style: italic;
            }
        }
    }

    .member-list {
        text-align: center;
        margin-top: 60px;
        padding-bottom: 40px;

        &-item {
            display: inline-block;
            a {
                width: 90px;
                height: 90px;
                border-radius: 100px;
                overflow: hidden;
                display: block;
                position: relative;
                transition: ease-in .6s all;
    
                img {
                    width: 100%;
                }

                &:after {
                    content: '';
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    position: absolute;
                    background: rgba($secondery-color, .6);
                    transition: ease-in .6s all;
                }

                &:hover {
                    transform: scale(1.2);
                    &:after {
                        background: rgba($secondery-color, .0); 
                    }
                }

                &.active {
                    
                    &:after {
                    background: rgba($secondery-color, .0); 
                    }
                }
            }

            &:not(:last-child) {
                margin-right: 50px; 
            }
        }
    }

}

.map {
    min-height: 100vh;
    
    padding-top: 40px;
    position: relative;
    overflow: hidden;

    .bg-rotat {
        background: linear-gradient(154deg, #f2f2f2 50%, #e2e2e2 50%);
        position: absolute;
        top: -400px;
        right: -400px;
        bottom: -400px;
        left: -400px;

    }

    .heading {
        font: 500 30px/46px $boldfont;
        text-align: center;
    }

    .map-bg {
        margin-top: 30px;
        position: relative;
        z-index: 1;
    }

    .pin {
        z-index: 99;
        .content {
            .counting {
                width: 85px;
                height: 85px;
                font: 700 56px/85px $prim-font;
                text-align: center;
                color: #fff;
                background: #ff8300;
                display: inline-block;
                border-radius: 10px;
            }

            .person-icon {
                display: inline-block;
                font: 300 30px/36px $prim-font;
                margin-left: 20px;
                img {
                    display: block;
                }
            }

            .location-icon {
                display: inline-block;
                img {
                    width: 65px;
                }
            }

        }

        &.Pakistan {
            display: inline-block;
            position: absolute;
            top: 127px;
            right: 190px;
        }

        &.uae {
            position: absolute;
            top: 350px;
            right: 500px;
        }

        &.jordan {
            position: absolute;
            top: 200px;
            right: 830px;
        }

        &.morocco {
            position: absolute;
            top: 180px;
        }
    }

    .statistic {
        text-align: left;
        // position: absolute;
        bottom: 20px;
        width: 60%;
        padding-bottom: 40px;

        .heading {
            text-align: left;
            font: 700 120px/140px $prim-font;
            color: #00a7e1;
        }

        .txt {
            font: 300 30px/46px $prim-font;
            color: #999;
        }

        .gander {
            text-align: center;

            .heading {
                text-align: center;
            }
        }
    }
}




    

.features {
    min-height: 100vh;
    background: $secondery-color;
    position: relative;
    overflow: hidden;

    &-bg {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;

        img {
            max-width: 50%;
            
            polygon.cls-2 {
                text-decoration: blink;
                -webkit-animation-name: blinker;
                -webkit-animation-duration: 0.6s;
                -webkit-animation-iteration-count:infinite;
                -webkit-animation-timing-function:ease-in-out;
                -webkit-animation-direction: alternate;
            }

        }
    }

    @-webkit-keyframes blinker {
        from {opacity: 1.0;}
        to {opacity: 0.0;}
    }

    &-group {
        max-width: 600px;
        margin: 0 auto;
        .features-list {
            &-item {
                margin-top: 100px;
                .number {
                    float: left;
                    width: 100px;
                    height: 100px;
                    position: relative;
                    text-align: center;
                    line-height: 98px;
                    margin-right: 20px;

                    .count {
                        font-size: 56px;
                        font-family: $prim-font;
                        font-weight: 700;
                        color: #fff;
                        position: relative;
                        z-index: 99;
                    }


                    &::after {
                        content: '';
                        width: 98px;
                        height: 98px;
                        background: linear-gradient(45deg, #0083AD 50%, #00A7E1 50%);
                        position: absolute;
                        border-radius: 27px;
                        top: 0;
                        left: 0;
                        transform: rotate(45deg);
                    }
                }

                .feature {
                    float: left;
                    font-size: 30px;
                    font-family: $prim-font;
                    font-weight: 300;
                    color: #fff;
                    line-height: 36px;
                }
            }
        }
    }
}

.likes {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background: #A5E5D9;

    &-bg {
        position: absolute;
        top: -500px;
        left: -200px;
        
        img {
            width: 90%;
        }
    }

    &-content {
        position: absolute;
        z-index: 99;
        bottom: 40px;
        left: 50%;

        .likes-list {
            .likes-item {
                float: left;
                margin-right: 150px;

                .count {
                    display: block;
                    color: #008065;
                    font-size: 56px;
                    font-family: $prim-font;
                    font-weight: 700;

                }

                .title {
                    font: 300 30px/36px $prim-font;
                }
            }
        }
    }
}


.idea {
    min-height: 100vh;
    padding-top: 60px;
    text-align: center;
    background: #0075A9;

    &-crcl {
        position: relative;

        .outer-crcl {
            width: 554px;
            height: 554px;
            background: rgba(#3fa9f5, .2);
            border-radius: 100%;
            margin: 0 auto;
            padding: 77px;
            display: block;

            .inner-crcl {
                width: 100%;
                height: 100%;
                background: rgba(#3fa9f5, .2);
                border-radius: 100%;
                margin: 0 auto;
                display: block; 
            }
        }

        

        .ideaimg {
            position: absolute;
            top: 90px;
            width: 250px;
            left: 50%;
            margin-left: -125px;
        }
    }
       
    &-content {
        margin-top: 80px;
        padding-bottom: 40px;
        .icon {
            float: left;
            img {
                width: 50px;
            }
        }

        .detail {
            float: left;
            text-align: left;
            margin-left: 40px;

            .title {
                font: 700 56px/56px $prim-font;
                color: #00c1de;
            }

            .txt {
                font: 300 30px/46px $prim-font;
                color: #fff;
            }
        }
    }
}

.rates {
    min-height: 100vh;
    background: linear-gradient(90deg, #00594e 50%, #56bfa3 50%);
    position: relative;

    .center-align {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    &-content {
        .border {
            padding: 100px 100px 30px 100px;
            border: 40px solid #fff;
            text-align: center;
            
            .row {
                border-bottom: 1px solid #fff;
                margin-top: 130px;
            }

            .smile-icon {
                img {
                    width: 70%;
                }
            }

            .events {
                position: absolute;
                bottom: 0;
                .detail {
                    text-align: left;
                    .title {
                        font: 700 56px $prim-font;
                        color: #56bfa3;
                    }

                    .txt {
                        font: 300 30px/46px $prim-font;
                        color: #fff;
                    }
                }
                
                .perc-bar {
                    height: 0px;
                    width: 90px;
                    background: #56bfa3;
                    border-right: 10px solid #007f6d;
                    transition: 3s ease-in all;
                }

                &.rate {
                    .perc-bar {
                        height: 0px;
                        width: 90px;
                        background: #007f6d;
                        border-right: 10px solid #00594e;
                        transition: 3.5s ease-in all;
                    }  

                    .detail {
                        .title {
                            color: #00594e;
                        }
                    }
                }

                &.animated {
                    .perc-bar { 
                        height: 250px;
                    }

                    &.rate {
                        .perc-bar {
                            height: 290px;
                        }
                    }
                }
            }



        }
    }
    
}

.all-sections {
    
    background: #14345a; /* Old browsers */
    background: -moz-linear-gradient(top, #14345a 0%, #2c8cc6 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #14345a 0%,#2c8cc6 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #14345a 0%,#2c8cc6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14345a', endColorstr='#3fa9f5',GradientType=0 ); /* IE6-9 */
    position: relative;
    .social {
        min-height: 100vh;
        position: relative;
        // border-bottom: 1px dashed #fff;
        .content {
            padding-top: 50px
        }

        .headings {
            .title {
                font: 700 30px $prim-font;
                color: #56bfa3;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding-bottom: 66px;
            }

            hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid rgba(#1dbfff, .2);
                margin: 1em 0;
                padding: 0; 
            }

            .detaile {
                color: #fff;
                font: 300 20px/24px $prim-font;
                text-transform: uppercase;
                padding: 40px 0px;
            }
        }

        .social-option {
            padding-left: 40px;
            margin-bottom: 110px;

            &:nth-child(5), &:nth-child(6) {
                margin-bottom: 0px;
            }
            .txt {
                font: 300 20px/24px $prim-font;
                color: #fff;
            }

            .heading {
                font: 500 26px/34px $prim-font;
                color: #56bfa3;
            }
        }
    }

    .progress {
        min-height: 100vh;
        position: relative;
        // border-bottom: 1px dashed #fff;

        .content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

        }

        .prog-container {
            width: 250px;
            margin: 0 auto;

            &.report {
                width: 350px;
            }
        }

        .social-option {
            padding-left: 40px;
            margin-bottom: 110px;

            &:nth-child(3) {
                margin-bottom: 0px;
            }
            .txt {
                font: 300 20px/24px $prim-font;
                color: #fff;
            }

            .heading {
                font: 500 26px/34px $prim-font;
                color: #56bfa3;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .permotion {
        min-height: 100vh;
        position: relative;
        // border-bottom: 1px dashed #fff;

        .content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

        }

        .prog-container {
            width: 550px;
            margin: 0 auto;
        }

        .social-option {
            padding-left: 40px;
            margin-bottom: 110px;

            &.no-padding {
                padding-bottom: 0px;
            } 

            &:nth-child(5), &:nth-child(6) {
                margin-bottom: 0px;
            }

            .txt {
                font: 300 20px/24px $prim-font;
                color: #fff;
            }

            .heading {
                font: 500 56px/65px $prim-font;
                color: #56bfa3;
            }
        }
    }

    .side-img {
        position: absolute;
        top: 340px;
        right: 0;
        width: 20%;

        .line1 {
            svg {
                width: 55%;
            }

            .lYDGWJbX_0{
                stroke-dasharray:957 959;
                stroke-dashoffset:958;
            }
            &.animated .lYDGWJbX_0{
                animation:lYDGWJbX_draw 3000ms linear 0ms forwards;
            }
            @keyframes lYDGWJbX_draw{
                100%{
                    stroke-dashoffset:0;
                }
            }
            @keyframes lYDGWJbX_fade{
                0%{
                    stroke-opacity:1;
                }
                94.44444444444444%{
                    stroke-opacity:1;
                }100%{
                    stroke-opacity:0;
                }
            }
        }

        .cam-icon {
            // margin-right: 200px;
            padding-right: 100px;
        }

        .line2 {
            svg {
                width: 60%;
            }

            .dqLrUdum_0{
                stroke-dasharray:587 589;
                stroke-dashoffset:588;
            }
            &.animated .dqLrUdum_0{
                animation:dqLrUdum_draw 3000ms linear 0ms forwards;
            }
            
            @keyframes dqLrUdum_draw{
                100%{stroke-dashoffset:0;}
            }
            
            @keyframes dqLrUdum_fade{
                0%{stroke-opacity:1;}
                93.54838709677419%{stroke-opacity:1;}
                100%{stroke-opacity:0;}
            }
        }

        .comp-icon {
            img {
                width: 80%;
                padding: 20px
            }
        }

        .line3 {
            svg {
                width: 60%;
            }
            .FprUuWcS_0{
                stroke-dasharray:587 589;
                stroke-dashoffset:588;
            }
            &.animated .FprUuWcS_0{
                animation:FprUuWcS_draw 1333ms linear 0ms forwards;
            }
            @keyframes FprUuWcS_draw{
                100%{stroke-dashoffset:0;}
            }
            @keyframes FprUuWcS_fade{
                0%{stroke-opacity:1;}
                93.54838709677419%{stroke-opacity:1;}
                100%{stroke-opacity:0;}
            }
        }

        .markete-icon {
            img {
                width: 80%;
            }
        }
    }
}


.spinner {
    margin: 0px auto 0;
    width: 70px;
    text-align: center;
    transform: rotate(90deg);
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #56bfa3;
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }


  .history {
      background: #0b3051;
      padding: 40px;

      .report-group {
          background: #14345a;
          padding: 40px;
          border-radius: 20px;
          text-align: center;

          .heading {
              text-align: center;
              color: #fff;
              font: 500 30px/36px $prim-font;
          }

          li {
              display: inline-block;
              margin-top: 40px;

              a {
                  font: 700 56px/65px $prim-font;
                  text-decoration: none;
                  color: #1dbfff;
              }

              &:not(:last-child) {
                  margin-right: 150px;
              }
          }
      }
  }

  @media(max-width:1800px) {
    .map {
        .pin {
            transform: scale(.7);
            z-index: 99;
    
            &.Pakistan {
                top: 127px;
                right: 150px;
            }
    
            &.uae {
                top: 270px;
                right: 350px;
            }
    
            &.jordan {
                top: 162px;
                right: 600px;
            }
    
            &.morocco {
                top: 135px;
                left: 72px;
            }
        }
    }
}


  @media(max-width:1500px) {
    .map {
        .pin {
            transform: scale(.7);
            z-index: 99;
    
            &.Pakistan {
                top: 127px;
                right: 150px;
            }
    
            &.uae {
                top: 270px;
                right: 350px;
            }
    
            &.jordan {
                top: 162px;
                right: 600px;
            }
    
            &.morocco {
                top: 135px;
                left: 72px;
            }
        }
    }
}

@media(max-width:1366px) {
    .map {
        .pin {
            transform: scale(.7);
            z-index: 99;
    
            &.Pakistan {
                top: 127px;
                right: 150px;
            }
    
            &.uae {
                top: 220px;
                right: 320px;
            }
    
            &.jordan {
                top: 162px;
                right: 550px;
            }
    
            &.morocco {
                top: 135px;
                left: 52px;
            }
        }

        .statistic {
            width: 100%;
        }

        
    }

    .likes-content {
        left: 10%;
    }
    .about-content .detail .heading {
        font: 36px/45px "helveticaneuebold";
    }
    .about-content .detail .txt {
        font: 18px/30px "helveticaneuelight";
    }
}

@media(max-width:1280px) {
    .map {
        .pin {
            transform: scale(.7);
            z-index: 99;
    
            &.Pakistan {
                top: 127px;
                right: 160px;
            }
    
            &.uae {
                top: 220px;
                right: 300px;
            }
    
            &.jordan {
                top: 162px;
                right: 508px;
            }
    
            &.morocco {
                top: 135px;
                left: 52px;
            }
        }
    }

    .history .report-group li a {
        font: 700 36px/65px "Helvetica Neue", Roboto;
    }
    .history .report-group li:not(:last-child) {
        margin-right: 100px;
    }
}

@media(max-width:1200px) {
    .map {
        .pin {
            transform: scale(.7);
            z-index: 99;
    
            &.Pakistan {
                top: 127px;
                right: 160px;
            }
    
            &.uae {
                top: 192px;
                right: 232px;
                transform: 6scale(.5);
            }
    
            &.jordan {
                top: 130px;
                right: 450px;
            }
    
            &.morocco {
                top: 132px;
                left: 10px;
            }
        }
    }

    .custom-container {
        padding-left: 50px;
        padding-right: 50px;
    }
}