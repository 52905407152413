.btn {
    text-decoration: none;
    color: #666;

    &-download {
       padding: 5px 20px;
       border: 3px solid #000;
       color: #000;
       border-radius: 50px;
       font-family: 'Halvitica', Roboto; 
       font-size: 18px;
    }
}